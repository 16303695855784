$(document).on("turbolinks:load", function() {
  $('form.registrazione input[type="checkbox"]').on('change', function(){
    if (!$('form.registrazione input[name="accetta_regolamento"]').is(':checked')) {
      return $('form.registrazione fieldset').attr('disabled', 'disabled');
    }
    if ($('form.registrazione input[type="checkbox"][name="accetta_clausole"]').length > 0 &&
      !$('form.registrazione input[name="accetta_clausole"]').is(':checked')) {
      return $('form.registrazione fieldset').attr('disabled', 'disabled');
    }
    $('form.registrazione fieldset').removeAttr('disabled');
  });
  
  if ($('form.registrazione input[type="checkbox"]').length > 0) {
    $('form.registrazione input[type="checkbox"]').trigger('change');
  }

  if ($('ul.nav-tabs').length > 0 && window.location.hash) {
    if ($(`a[href="${window.location.hash}"]`).length > 0) {
      $(`a[href="${window.location.hash}"]`).click();
      $('div.challenge-menu button').html($(`a[href="${window.location.hash}"]`).html());
    }
  }

  $('a[href="#learning"]').on('click', function(){
    setTimeout(function(){
      $(".chat").animate({ scrollTop: $(document).height() }, 1000);
    }, 250);
  });

  $('.challenge-menu.nav-tabs a').click(function (e) {
    window.location.hash = $(this).attr('href');
  });

  $('form.needs-validation').on('submit', function(event){
    $('form.needs-validation').find('button[type="submit"]').attr('disabled', 'disabled');
    var form = $(this);
    var valid = true;
    if (!$('form.needs-validation').find('input[name="descr"]').val()) valid = false;
    if ($('form.needs-validation').find('input[name="file"]').get(0).files.length === 0) valid = false;
    if (valid) {
      form.addClass('was-validated');
      $('form.needs-validation').find('button[type="submit"]').html(
        `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Invio...`
      );
      return true;
    } else {
      event.preventDefault();
      event.stopPropagation();
      $('form.needs-validation').find('button[type="submit"]').removeAttr('disabled');
      return false;
    }
  });

  $('button.show-descr').find('.descr').hide();
  $('button.show-descr').on('mouseover', function(){
    $(this).find('.descr').show();
    $(this).find('.tot').css({color: 'white'});
  });
  $('button.show-descr').on('mouseout', function(){
    $(this).find('.descr').hide();
    $(this).find('.tot').css({color: 'black'})
  });
});