import consumer from "./consumer"

// consumer.subscriptions.create("RoomChannel", {
//   connected() {
//     // Called when the subscription is ready for use on the server
//   },
// 
//   disconnected() {
//     // Called when the subscription has been terminated by the server
//   },
// 
//   received(data) {
//     // Called when there's incoming data on the websocket for this channel
//   }
// });

$(document).on("turbolinks:load", function() {
  setTimeout(function(){
    $('[data-channel-subscribe="room"]').animate({ scrollTop: (1000 * 1000 * 1000)}, 1000);
  }, 500);

  $('[data-channel-subscribe="room"]').each(function(index, element) {
    // console.log("Sottoscrivo", index, element);
    var $element = $(element),
        room_id = $element.data('room-id'),
        user_id = $element.data('user-id'),
        message_for = $element.data('message-for'),
        messageTemplate = $('[data-role="message-template"]');

    $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)        

    consumer.subscriptions.create(
      {
        channel: "RoomChannel",
        room: room_id
      },
      {
        received: function(data) {
          // console.log(data);
          if (message_for) {
            var skip = true;
            if (data.user_id == message_for) skip = false;
            if (data.message_for == message_for) skip = false;
            if (skip) return false;
          }

          // INSERISCO LA DATA SE NON E' PRESENTE
          if ($('[data-channel-subscribe="room"]').
            find(`.chat-message-date span:contains('${data.created_date}')`).
            length == 0) {
            var content = $('[data-role="date-template"]').children().clone(true, true);
            content.find('[data-role="curday"]').text(data.created_date);
            $element.append(content);
          }

          var content = messageTemplate.children().clone(true, true);
          content.find('[data-role="user-avatar"]').attr('src', data.user_avatar_url);
          content.find('[data-role="message-text"]').text(data.message);
          // content.find('[data-role="message-date"]').text(data.updated_at);
          content.find('[data-role="message-date"]').text(data.created_hour);
          if (data.user_id == user_id) {
            content.find('.message-content').addClass('mine');
            content.find('.other-avatar').hide();
          } else {
            content.find('.mine-avatar').hide();
          }
          $element.append(content);
          $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000);
        }
      }
    );
  });
});
