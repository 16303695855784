$(function() {
  "use strict";

  // https://codepen.io/ahelja/pen/jqgGvQ
  function createCookie(name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000)); 
      var expires = "; expires="+date.toGMTString(); 
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/"; 
  }
 
  function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  var cookieDuration = 14;          // Number of days before the cookie expires, and the banner reappears
  var cookieName = 'beeVivaCookie'; // Name of our cookie
  var cookieValue = 'on';           // Value of cookie
  if (checkCookie(cookieName) != cookieValue){
    $('#privacy-cookies').show();
  }

  $('#privacy-cookies-button').on('click', function(){
    createCookie(cookieName, cookieValue, cookieDuration); // Create the cookie
    $('#privacy-cookies').hide();
  });
});