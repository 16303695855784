// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/application'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import "popper.js"
import 'bootstrap/dist/js/bootstrap'

import "./cookies.js"
import "./competition.js"
import "./room.js"
// import './cookie_law.js';
// $(document).on("turbolinks:load", function() {
//   setTimeout(function(){
//     $('p#banner_notice, p#banner_alert').hide();
//   }, 3500);
// });
$(document).on("turbolinks:load", function() {
  $('#preloader').hide();
  // setTimeout(function(){
  //   $('div.main-alert').hide(1500);
  // }, 10500);
})
